import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "checkbox"]

  connect() {
    // Set initial button state based on checkbox
    this.toggleButtonState()
  }

  toggleButtonState() {
    // Disable button if checkbox is unchecked, enable if checked
    this.buttonTarget.disabled = !this.checkboxTarget.checked
  }
}
