import Appsignal from '@appsignal/javascript'
import { plugin } from '@appsignal/plugin-window-events'

/**
 * Call at page initialization to initialize Appsignal logging
 *
 * @param {Object} [config] - Configuration options
 * @param {boolean} [config.reportErrors] - Whether to report all unhandled errors to our error monitoring service.
    Note we avoid enabling it in Platform because we are afraid of being flooded with all sort of errors from Retailers websites.
*/
export function initLogging ({ reportErrors = false } = {}) {
  const globals = window.provenance
  if (!globals) return
  if (
    typeof globals.appsignalApiKey !== 'undefined' ||
    typeof globals.applicationEnvironment !== 'undefined'
  ) {
    const appsignal = new Appsignal({
      key: globals.appsignalApiKey,
      revision: globals.revision
    })

    if (reportErrors) {
      appsignal.use(plugin())
    }

    globals.appsignal = appsignal
  }
}

// Log an error to Appsignal only
export function internalLog (err) {
  const appsignal = window.provenance.appsignal
  if (appsignal) {
    appsignal.sendError(err)
  }
}

// Log an error to Appsignal and the JS console
export function log (err) {
  // eslint-disable-next-line no-console
  console.log(err.message)

  internalLog(err)
}
